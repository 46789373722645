<script setup lang="ts">
import type { Page } from '~/types'

const route = useRoute()
const seoStore = useSeoStore()

const { data, pending, error } = useAsyncData('page-slug', () => {
	return useAPIFetch<Page>('/v1/pages/slug/' + route.params.slug)
})

if (error.value) {
	throw createError({
		fatal: true,
		statusCode: 404,
	})
}

seoStore.title = data.value?.title
</script>

<template>
	<div
		class="pt-6 max-md:px-4"
	>
		<template v-if="pending">
			<UIcon
				name="i-svg-spinners-ring-resize"
				class="text-primary w-6 h-6 mx-4 sm:mx-0 mt-6"
				dynamic
			/>
		</template>
		<template v-else-if="data">
			<h1 class="text-2xl md:text-4xl font-bold mb-4">
				{{ data.title }}
			</h1>
			<PageDetail
				:item="data"
			/>
		</template>
	</div>
</template>